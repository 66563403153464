<template>
  <div class="container py-20 lg:py-32">
    <div class="mb-8 flex items-center justify-between gap-2 lg:mb-14">
      <Heading v-if="data.title" :content="data.title" :level="2" class="font-bold" />
      <div v-if="data.button?.length">
        <Button
          v-for="button in data.button"
          :key="button.id"
          :variant="button.primary ? 'primary' : 'secondary'"
          :label="button.title"
          :target="button.externalLink ? '_blank' : undefined"
          :to="
            button.pageLink !== null
              ? localePathByType(
                  button.pageLink?.__typename as string,
                  button.pageLink?.slug as string
                )
              : button.externalLink
          "
          :size="isLG ? 'default' : 'small'"
        />
      </div>
    </div>
    <ArticleList :data="relatedArticles" is-related-article />
  </div>
</template>

<script setup lang="ts">
import type { ArticleRecord, SectionRelatedArticleRecord } from '~/types/generated'

import { theme } from '#tailwind-config'

const props = defineProps({
  data: {
    type: Object as PropType<SectionRelatedArticleRecord>,
    default: () => {}
  }
})

const { localePathByType } = useLocalePathByType()

const { locale } = useI18n()
const relatedArticles = ref<ArticleRecord[]>([])
const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')

const filterAny = props.data.categories?.length
  ? props.data.categories.map((x: { id: any }) => x.id)
  : []

const { data: articles } = await useAsyncGql('articles', {
  // @ts-ignore
  locale,
  first: 3,
  anyIn: filterAny
})

if (articles.value) {
  relatedArticles.value = articles.value.allArticles
}

// Add CMS Overrides
if (Array.isArray(props.data.overrideArticles) && props.data.overrideArticles.length) {
  relatedArticles.value.unshift(...props.data.overrideArticles)
  relatedArticles.value = relatedArticles.value.slice(0, 3)
}
</script>
